let service = {}

if (process.env.VUE_APP_SERVER_ENV == 'production') {
  service.gateway = 'https://gateway.prod.experiencethis.co'
  service.master_venue = 'https://gateway.prod.experiencethis.co/master-venue'
} else if (process.env.VUE_APP_SERVER_ENV == 'demo') {
  service.gateway = 'https://gateway.demo.experiencethis.co'
  service.master_venue = 'https://gateway.demo.experiencethis.co/master-venue'
} else if (process.env.VUE_APP_SERVER_ENV == 'uat') {
  service.gateway = 'https://gateway.uat.experiencethis.co/gateway'
  service.master_venue = 'https://gateway.uat.experiencethis.co/master-venue'
} else if (process.env.VUE_APP_SERVER_ENV == 'staging') {
  service.gateway = 'http://api.staging.experiencethis.co:4050/api'
  service.master_venue =
    'http://api.staging.experiencethis.co:4024/master-venue'
} else {
  service.gateway = 'https://gateway.dev.experiencethis.co'
  // service.gateway = 'https://gateway.prod.experiencethis.co'
  // service.gateway = 'http://localhost:4050/api'
  // service.gateway = 'https://gateway.uat.experiencethis.co/gateway'
  service.master_venue = 'http://api.dev.experiencethis.co:4024/master-venue'
}

export default service

import styled, { css } from 'vue3-styled-components'

export const Container = styled.div`
  ${({ theme: { shades } }) => css`
    border-bottom: 1px solid ${shades[1]};
    width: 100%;
    height: 72px;
    display: grid;
    padding: 0 40px;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    position: relative;
    .WrapperCenter {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .WrapperStart {
      display: flex;
      align-items: center;
    }
    .WrapperEnd {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  `}
`

export const EtLogo = styled.img`
  ${({ mobile }) => css`
    height: 18px;
    width: 215.05px;
    cursor: pointer;

    @media only screen and (max-width: 500px) {
      display: none;
    }

    ${mobile
      ? `
    display: none;
    `
      : `
    @media only screen and (max-width: 500px) {
      display: none;
    }
    `}
  `}
`

export const MobileEtLogo = styled.div`
  ${({ theme: { primary }, mobile }) => css`

    height: 22px;
    width: 22px;
     .icon-Experience-This-Logo-1 {
      font-size: 22px;
      display: flex; 
      align-items: center;
      justify-content: center;
      color: ${primary};
    }
    cursor: pointer;
    ${mobile
      ? `
      display: block;
    `
      : `
        display: none;
    @media only screen and (max-width: 500px) {
      display: block;
    }
    `}
  `}
`

export const MenuContainer = styled.div`
  ${({ grid }) => css`
  display: grid;
  grid-template-columns: repeat(${grid}, 1fr);
  gap: 5px;
  place-items: center;
  width: ${grid === 2 ? `278px;` : `518PX;`};
  align-items: center;
  position: relative;
  .GetStart {
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      background: #F3F3F3;
      width: 116px;
      padding: 10px 0;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 950px) {
    display: none;
  }
  `}
`

export const RightMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 87px;
  align-items: center;
  .icon {
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }
`

export const BellLogo = styled.img`
  height: 16px;
  width: 16px;
`
export const RoundWrapper = styled.div`
  ${({ theme: { shades }, hoverColor }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${shades[1]};
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
  
    
    ${hoverColor
      ? `
        &:hover {
          background-color: ${shades[1]};
        }
      `
      : ''}
  `}
`


export const RoundWrapperName = styled.div`
  ${({ theme: { shades }, hoverColor, mobile }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${shades[1]};
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    cursor: pointer;

    ${mobile
      ? `display: none;`
      : `
            @media only screen and (max-width: 950px) {
              display: none;
            }
          `}
    ${hoverColor
      ? `
        &:hover {
          background-color: ${shades[1]};
        }
      `
      : ''}
  `}
`


export const RoundWrapperBurger = styled.div`
  ${({ theme: { shades }, hoverColor, mobile }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${shades[1]};
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    .icon-menu-1 {
     font-size: 22px;
     width: 22px;
     height: 22px;
     display: flex;
     align-items: center;
     justify-content: center;
    }
    ${mobile
      ? `display: flex;`
      : `
            display: none;
            @media only screen and (max-width: 950px) {
                display: flex;
                align-items: center;
            }
          `}

    ${hoverColor
      ? `
        &:hover {
          background-color: ${shades[1]};
        }
      `
      : ''}
  `}
`

export const NameLogo = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  span {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 950px) {
    display: none;
  }
`

export const BurgerLogo = styled.img`
  height: 18px;
  width: 18px;
`
